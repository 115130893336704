import { Field, FormikProvider, getIn, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { number, object, ref, string } from 'yup';
import ApiResponseError from '../../../../../../Axios/ApiResponseError';
import googleApi from '../../../../../../Axios/GoogleApi';
import MovexResponseError from '../../../../../../Axios/MovexResponseError';
import { Alert, Loading, ShowPleaseWait, useAlert } from '../../../../../../Components';
import { FormControlKeys, OptionType } from '../../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import {
     FieldError,
     FieldSetContainer,
     FieldSetHeader,
     FormContainer,
     FormFieldSetContainer,
     LineBreak,
     Margin20BottomContainer
} from '../../../../../../Components/Formik/StyledComponents';

import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { admin } from '../../../../../../Axios/Screens';
import { InfoIcon } from '../../../../../../Components/UI/Members.styles';
import { StreetAddressResponse } from '../../../../../../Models/GoogleAPI/DistanceMatrix';
import {
     AddMemberModel,
     AddMemberRequestModel,
     AdminMembersDocumentViewModel,
     GetMemberResponse
} from '../../../../../../Models/Members/AddMember';
import { TradePlate, TransporterPlate } from '../../../../../../Models/Members/AddMember/TradePlates';
import { AddTradePlateRequest, AddTransporterPlateRequest } from '../../../../../../Models/Members/EditMember/AddTradePlatesRequest';
import { RouteConstants } from '../../../../../../Routes/Constants';
import { useAppDispatch } from '../../../../../../Store/hooks';
import { addMemberActionCreators } from '../../../../../../Store/Members/AddMember/Action';
import { ApplicationState } from '../../../../../../Store/Types';
import { ApiErrorResponse, EntityCreatedOrUpdatedViewModel, MovexErrorResponse } from '../../../../../../Types';
import { uploadFileToBlob } from '../../../../../../Utils/BlobStorageServiceUpload';
import {
     ActiveStatus,
     CreditPaymentType,
     CreditRisk,
     DocumentType,
     HTTP_STATUS_CODES,
     InvoiceFrequency,
     MemberType,
     PaymentMethod,
     ServiceType
} from '../../../../../../Utils/Enums';
import {
     convertEnumToSelectListWithIntegerValue,
     convertNullsToEmptyStrings,
     getPostCodeLookupAddress,
     isApiError,
     isMovexErrorResponse,
     isSuccessEntityCreatedOrUpdatedResponse,
     parsePlateInput,
     roundToPlaces,
     scrollToFirstError,
     verifyPlateInput
} from '../../../../../../Utils/Helper';
import { useGetStorageServiceSasToken } from '../../../../Jobs/EditJob/Hooks';
import { MemberKeys } from '../../../AddMember/Types';
import {
     AdminCountryOptions,
     AdminMemberTypeOptions,
     AvgVehiclesMovedOptions,
     HearAboutMovexOptions,
     InvoiceFrequencyOptions,
     MembershipActiveDisableReasonOptions,
     MembershipActiveStatusOptions,
     MonthlyFeeTypeOptions,
     PaymentMethodOptions,
     TradingStatusOptions,
     TypeOfBusinessOptions,
     YesNoOptions
} from '../../../Constants';
import { MemberIDParam } from '../MemberBlocks/Types';
import { getCustomerSetForDropdown, getGroupsForDropdown, getLanguagesForDropdown } from './fetchOptions';
import FileUploadContent from './handleFileUploadContent';
import { usePostAddMember, usePutUpdateMember } from './Hooks';
import { usePostAddTradePlates } from './Hooks/usePostAddTradePlates';
import { usePostAddTransporterPlates } from './Hooks/usePostAddTransporterPlates';
import { PlatesList } from './TradePlates/PlatesList';

const validationSchema = object({
     [MemberKeys.UserName]: string().required(),
     [MemberKeys.Email]: string().required(),
     [MemberKeys.ConfirmPassword]: string().oneOf([ref('Password')], 'Passwords must match'),
     [MemberKeys.Landline]: string()
          .nullable()
          .matches(/\d\d\d\d\d\d\d\d+$/, 'Please enter at least 8 digits and use numbers only!'),
     [MemberKeys.Mobile]: string()
          .nullable()
          .matches(/\d\d\d\d\d\d\d\d+$/, 'Please enter at least 8 digits and use numbers only!'),
     [MemberKeys.Partner_Landline]: string()
          .nullable()
          .matches(/\d\d\d\d\d\d\d\d+$/, 'Please enter at least 8 digits and use numbers only!'),
     [MemberKeys.Partner_Mobile]: string()
          .nullable()
          .matches(/\d\d\d\d\d\d\d\d+$/, 'Please enter at least 8 digits and use numbers only!'),
     [MemberKeys.Company_YearEstablished]: string()
          .nullable()
          .matches(/^[0-9]{4}$/, 'The field Year Established must be a number and of 4 digits only.'),
     [MemberKeys.InvoiceMaxLineItems]: number().nullable().typeError('The field InvoiceMaxLineItems  must be a number.'),
     [MemberKeys.NoOfTransporters]: number().nullable().typeError('The field NoOfTransporters  must be a number.'),
     [MemberKeys.InvoiceMaxAmount]: number().nullable().typeError('The field InvoiceMaxAmount  must be a number.'),
     [MemberKeys.PostCode]: string()
          .nullable()
          .when([MemberKeys.Active], (active, schema) =>
               active.toString() === ActiveStatus.Active.toString() ? schema.required() : schema
          ),
     [MemberKeys.AccountNo]: string().matches(/^[0-9]{8}$|^$/, 'Please enter a valid 8 digit account number'),
});

const GeneralForm: React.FC<GeneralFormProps> = ({ isGetMemberLoading, getMemberResponse, getMemberError, refetchMember }): JSX.Element => {
     const apiUrl = useSelector((state: ApplicationState) => state.apiUrlState.apiUrl);
     const { memberID: paramMemberID } = useParams<MemberIDParam>();
     const memberID = paramMemberID ? parseInt(paramMemberID) : 0;
     const isEditMode = memberID > 0;
     const documentTitle = isEditMode ? '' : 'You must add the user before adding documents';
     const dispatch: ThunkDispatch<ApplicationState, null, AnyAction> = useAppDispatch();

     /** useStates */
     const [customerSetID, setCustomerSetID] = useState<number | undefined>();
     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [formikInitialValues, setFormikInitialValues] = useState<AddMemberModel>({
          UserName: '',
          Email: '',
          Password: '',
          ConfirmPassword: '',
          PostCode: '',
          RegisteredPostCode: '',
          Active: false,
          Disabled: false,
          MonthlyFeeType: 0,
          NoOfLowLoaders: 0,
          NoOf1CarTransporters: 0,
          NoOf2To3CarTransporters: 0,
          NoOf5To7CarTransporters: 0,
          NoOf8To9CarTransporters: 0,
          NoOf10PlusCarTransporters: 0,
          InvoiceFrequency: InvoiceFrequency.Monthly,
          PaymentMethod: PaymentMethod.Pending,
          TradePlates: '',
          OnlyAcceptMovexPocPods: false,
     });
     const [groupsForDropdown, setGroupsForDropdown] = useState<OptionType[]>([]);
     const [customerSetForDropdown, setCustomerSetForDropdown] = useState<OptionType[]>([]);
     const [languagesForDropdown, setLanguagesForDropdown] = useState<OptionType[]>([]);
     const [documents, setDocuments] = useState<AdminMembersDocumentViewModel[]>(getMemberResponse?.Documents || []);
     const [postCodeLookup, setPostCodeLookup] = useState<OptionType[]>([]);
     const [postCodeLookupError, setPostCodeLookupError] = useState<string>();
     const [streetAddress, setStreetAddress] = useState<StreetAddressResponse>();
     const [isPostCodeLookupLoading, setIsPostCodeLookupLoading] = useState(false);
     const [registeredPostCodeLookup, setRegisteredPostCodeLookup] = useState<OptionType[]>([]);
     const [registeredPostCodeLookupError, setRegisteredPostCodeLookupError] = useState<string>();
     const [isRegisteredPostCodeLookupLoading, setIsRegisteredPostCodeLookupLoading] = useState(false);
     const [sortCode1, setSortCode1] = useState<string>();
     const [sortCode2, setSortCode2] = useState<string>();
     const [sortCode3, setSortCode3] = useState<string>();
     const [remainingLimit, setRemainingLimit] = useState<number>(0.0);
     const [usedLimit, setUsedLimit] = useState<number>(0.0);
     const [hasBankDetailPermission, setHasBankDetailPermission] = useState<boolean>(true);
     const [proofOfPartnerAddressDocs, setProofOfPartnerAddressDocs] = useState<any[]>([]);
     const [imageFiles, setImageFiles] = useState<any[]>([]);
     const [isCreditEditable, setIsCreditEditable] = useState<boolean>(false);
     const [addUpdateMemberResponse, setAddUpdateMemberResponse] = useState<{
          response?: EntityCreatedOrUpdatedViewModel;
          error: any;
     }>({ response: { Id: 0 }, error: null });
     const [displayIsModelSupplier, setDisplayIsModelSupplier] = useState(false);

     /** Custom Hook */
     const { alert, setAlert, handleAlertClose } = useAlert();

     /** Query Hooks */
     const {
          mutate: putUpdateMember,
          isLoading: isPutUpdateMemberLoading,
          data: updateMemberResponse,
          error: updateMemberError,
     } = usePutUpdateMember();

     const {
          mutate: postAddMember,
          isLoading: isPostAddMemberLoading,
          data: addMemberResponse,
          error: addMemberError,
     } = usePostAddMember();

     const {
          data: sasToken,
          error: sasTokenError,
          refetch: getSasToken,
     } = useGetStorageServiceSasToken();

     const {
          mutate: postAddTradePlates,
          data: postAddTradePlatesData,
          error: postAddTradePlatesError,
     } = usePostAddTradePlates();

     const {
          mutate: postAddTransporterPlates,
          data: postAddTransporterPlatesData,
          error: postAddTransporterPlatesError,
     } = usePostAddTransporterPlates();

     /** Functions */
     const onSubmit = (payload: AddMemberModel): void => {
          payload.SortCode = (sortCode1 || '') + (sortCode2 || '') + (sortCode3 || '');

          if (payload.ServiceTypeDriven && payload.ServiceTypeTransported) {
               payload.ServiceType = ServiceType.Both;
          } else if (payload.ServiceTypeDriven) {
               payload.ServiceType = ServiceType.Driven;
          } else if (payload.ServiceTypeTransported) {
               payload.ServiceType = ServiceType.Transported;
          } else {
               payload.ServiceType = ServiceType.Unknown;
          }

          if (payload.IsModelSupplier && payload.ModelAccountId == '') {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'MoDel Account ID is required',
                    body: 'A MoDel Account ID is required when the user is a MoDel Supplier',
               });
               return;
          }

          const request: AddMemberRequestModel = {
               Member: payload,
               Documents: documents,
               CustomerSetID: customerSetID,
          };
          if (isEditMode) {
               putUpdateMember(request);
          } else {
               postAddMember(request);
          }
     };

     const onPostCodeLookup = (
          e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
          postCode: string,
          isRegisteredPostCode: boolean
     ) => {
          {
               e.preventDefault();
               isRegisteredPostCode ? setIsRegisteredPostCodeLookupLoading(true) : setIsPostCodeLookupLoading(true);
               googleApi.streetAddress.getFullAddressByPostCode(postCode).then((res) => {
                    setStreetAddress(res);
                    if (res.Thoroughfares) {
                         const finalAddressLookup = getPostCodeLookupAddress(res);
                         const lookupData = [{ label: 'Select', value: '' }, ...finalAddressLookup];
                         if (isRegisteredPostCode) {
                              setRegisteredPostCodeLookup(lookupData);
                              setRegisteredPostCodeLookupError('');
                         } else {
                              setPostCodeLookup(lookupData);
                              setPostCodeLookupError('');
                         }
                    } else {
                         const errorMessage = 'This postcode is not valid, please try again or enter your address manually';
                         if (isRegisteredPostCode) {
                              setRegisteredPostCodeLookup([]);
                              setRegisteredPostCodeLookupError(errorMessage);
                         } else {
                              setPostCodeLookup([]);
                              setPostCodeLookupError(errorMessage);
                         }
                    }
                    isRegisteredPostCode ? setIsRegisteredPostCodeLookupLoading(false) : setIsPostCodeLookupLoading(false);
               });
          }
     };

     const onPostCodeLookupChange = (isRegisteredPostCodeChanged: boolean) => {
          if (streetAddress) {
               const { Postal_county, Town, Thoroughfares } = streetAddress;
               const t = Thoroughfares[0];
               if (!isRegisteredPostCodeChanged) {
                    formik.setFieldValue(MemberKeys.Company, t.Delivery_points[0].Organisation_name);
               }

               formik.setFieldValue(
                    isRegisteredPostCodeChanged ? MemberKeys.RegisteredAddress1 : MemberKeys.Address1,
                    `${t.Delivery_points[0].Building_number} ${t.Thoroughfare_name} ${t.Thoroughfare_descriptor}`
               );
               formik.setFieldValue(isRegisteredPostCodeChanged ? MemberKeys.RegisteredCounty : MemberKeys.County, Postal_county);
               formik.setFieldValue(isRegisteredPostCodeChanged ? MemberKeys.RegisteredTown : MemberKeys.Town, Town);
          }
     };

     const onSubmitSuccess = () => {
          handleAlertClose();
          window.location.href = `${RouteConstants.Members}`;
     };

     const onFileChange = (event: Event, type: number) => {
          const target = event.target as HTMLInputElement;
          const files = target.files as FileList;
          const uploadedDocuments =
               type === DocumentType.CreditReport || type === DocumentType.PhotoIdentification
                    ? documents.filter((x) => x.Type !== type.toString())
                    : documents;
          const newDocument: AdminMembersDocumentViewModel = {
               Name: files[0].name,
               Size: files[0].size,
               Type: type.toString(),
               IsUploaded: true,
          };
          uploadFileToBlob(files[0], sasToken ?? '');
          setDocuments([...uploadedDocuments, newDocument]);
     };

     const isDocumentUploaded = (type: number) => {
          return documents.some((d) => d.Type == type.toString());
     };

     const memoizedDocuments = useMemo(() => documents || [], [documents]);


     const removeUploadedDocument = (fileInputName: string, docName: string, type: string) => {
          setFieldValue(fileInputName, '');
          const docs = documents.filter((d) => !(d.Name === docName && d.Type == type));
          setDocuments(docs);
     };

     const removeAllUploadedDocumentsByType = (fileInputName: string, type: number) => {
          setFieldValue(fileInputName, '');
          const docs = documents.filter((d) => d.Type != type.toString());
          setDocuments(docs);
     };

     const showApiError = (error: ApiErrorResponse) => {
          const { Status: status, Errors: errors, Title: title } = error;
          if (status === HTTP_STATUS_CODES.BAD_REQUEST) {
               setAlert({
                    ...alert,
                    show: true,
                    header: title,
                    body: ApiResponseError.getBadRequestErrorContent(errors),
               });
          }
     };

     const showMovexError = (error: MovexErrorResponse) => {
          setAlert({
               ...alert,
               show: true,
               body: MovexResponseError.getMovexErrorContent(error).body,
          });
     };

     /** Formik */
     const formik = useFormik({
          initialValues: formikInitialValues,
          validationSchema: validationSchema,
          onSubmit: onSubmit,
          enableReinitialize: true,
          validateOnChange: false,
     });

     /** useEffects */
     useEffect(() => {
          const fetchPermissions = async () => {
               try {
                    const result = await admin.getAdminUIPermissions();
                    if (result.AdminPermissions?.find(x => x.Permission === 'AdminUsers|UpdateCreditLimit')) {
                         setIsCreditEditable(true);
                    }
               } catch (error) {
                    console.error(error);
               }
          };
          fetchPermissions();
     }, []);

     useEffect(() => {
          getGroupsForDropdown().then((results) => {
               setGroupsForDropdown(results);
          });

          getCustomerSetForDropdown().then((results) => {
               setCustomerSetForDropdown(results ?? []);
          });

          getLanguagesForDropdown().then((results) => {
               setLanguagesForDropdown(results ?? []);
          });
          getSasToken();
     }, []);

     useEffect(() => {
          if (getMemberResponse) {
               setIsLoading(isGetMemberLoading ?? true);
               const { Member: member } = getMemberResponse;
               convertNullsToEmptyStrings(member);
               setHasBankDetailPermission(getMemberResponse.HasBankDetailPermission);

               if (member) {
                    const { SortCode, MovexSetLimit } = member;
                    const limit = roundToPlaces(MovexSetLimit || 0, 2);
                    const remainingCredit = roundToPlaces(getMemberResponse.RemainingCredit || 0, 2);
                    getMemberResponse.Documents.forEach((report: any) => {
                         report.Type = DocumentType[report.Type.toString()];
                    });

                    if (getMemberResponse.HasBankDetailPermission) {
                         setSortCode1(SortCode?.substring(0, 2));
                         setSortCode2(SortCode?.substring(2, 4));
                         setSortCode3(SortCode?.substring(4, 6));
                    }

                    setDisplayIsModelSupplier(member.MemberType === MemberType.TransportCompany);
                    setRemainingLimit(remainingCredit);
                    setUsedLimit(roundToPlaces(limit - remainingCredit, 2));
                    setDocuments(getMemberResponse.Documents);
                    setCustomerSetID(getMemberResponse.CustomerSetId || 0);
                    setFormikInitialValues({
                         ...formikInitialValues,
                         ...member,
                         ServiceTypeDriven: (getMemberResponse?.Member?.ServiceType == ServiceType.Both || getMemberResponse?.Member?.ServiceType == ServiceType.Driven) || false,
                         ServiceTypeTransported: (getMemberResponse?.Member?.ServiceType == ServiceType.Both || getMemberResponse?.Member?.ServiceType == ServiceType.Transported) || false,
                         Password: '',
                         ConfirmPassword: '',
                    });
               }
          }
     }, [getMemberResponse]);

     useEffect(() => {
          if (postAddTradePlatesData) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Trade Plates Status Update',
                    body: (
                         <div>
                              <p>Trade Plates Status:</p>
                              <ul>
                                   {postAddTradePlatesData.SavedPlates.length > 0 ? (
                                        postAddTradePlatesData.SavedPlates?.map((plate) => (
                                             <li key={plate}>Successfully added: {plate}</li>
                                        ))
                                   ) : (
                                        <li>No successful trade plates</li>
                                   )}
                              </ul>
                              {postAddTradePlatesData.FailedPlates.length > 0 && (
                                   <ul>
                                        {postAddTradePlatesData.FailedPlates?.map(({ Plate, Reason }) => (
                                             <li key={Plate}>
                                                  Failed Plate: {Plate} (Error: {Reason})
                                             </li>
                                        ))}
                                   </ul>
                              )}
                         </div>
                    ),
               });
          } else if (postAddTradePlatesError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Error when adding plate!',
                    body: postAddTradePlatesError?.Description,
               });
          }
     }, [postAddTradePlatesData, postAddTradePlatesError]);

     useEffect(() => {
          if (postAddTransporterPlatesData) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Transporter Plates Status Update',
                    body: (
                         <div>
                              <p>Transporter Plates Status:</p>
                              <ul>
                                   {postAddTransporterPlatesData.SavedPlates.length > 0 ? (
                                        postAddTransporterPlatesData.SavedPlates?.map((plate) => (
                                             <li key={plate}>Successfully added: {plate}</li>
                                        ))
                                   ) : (
                                        <li>No successful transporter plates</li>
                                   )}
                              </ul>
                              {postAddTransporterPlatesData.FailedPlates.length > 0 && (
                                   <ul>
                                        {postAddTransporterPlatesData.FailedPlates?.map(({ Plate, Reason }) => (
                                             <li key={Plate}>
                                                  Failed Plate: {Plate} (Error: {Reason})
                                             </li>
                                        ))}
                                   </ul>
                              )}
                         </div>
                    ),
               });
          } else if (postAddTransporterPlatesError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: 'Error when adding plates!',
                    body: postAddTransporterPlatesError?.Description,
               });
          }
     }, [postAddTransporterPlatesData, postAddTransporterPlatesError]);

     useEffect(() => {
          const request: AddMemberRequestModel = {
               Member: formikInitialValues,
               Documents: documents,
               CustomerSetID: customerSetID,
          };
          dispatch(addMemberActionCreators.getMemberFormValues(request));
     }, [formikInitialValues, documents]);

     useEffect(() => {
          setIsLoading(isLoading);
     }, [isLoading]);

     useEffect(() => {
          setAddUpdateMemberResponse({
               response: updateMemberResponse,
               error: updateMemberError,
          });
     }, [updateMemberResponse, updateMemberError]);

     useEffect(() => {
          setAddUpdateMemberResponse({
               response: addMemberResponse,
               error: addMemberError,
          });
     }, [addMemberError, addMemberResponse]);

     useEffect(() => {
          if (addMemberResponse || updateMemberResponse) {
               setAlert({
                    ...alert,
                    show: true,
                    body: 'Save Successful!',
               });
          }
     }, [addMemberResponse, updateMemberResponse])

     useEffect(() => {
          if (addUpdateMemberResponse) {
               const {
                    response,
                    error } = addUpdateMemberResponse;
               if (error) {
                    if (isMovexErrorResponse(error)) {
                         showMovexError(error);
                    } else if (isApiError(error)) {
                         showApiError(error as ApiErrorResponse);
                    }
               }

               if (isSuccessEntityCreatedOrUpdatedResponse(response) && response && response?.Id > 0) {
                    setAlert({
                         ...alert,
                         show: true,
                         header: 'Saved successfully',
                         closeCallback: onSubmitSuccess,
                    });
               }
          }
     }, [addUpdateMemberResponse]);

     useEffect(() => {
          if (Object.keys(formik.errors).length > 0) {
               document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
          }
     }, [formik.isValid]);

     useEffect(() => {
          setShowPleaseWait(isPostAddMemberLoading || isPutUpdateMemberLoading);
     }, [isPostAddMemberLoading, isPutUpdateMemberLoading]);

     useEffect(() => {
          if (updateMemberError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: updateMemberError?.Subject,
                    body: updateMemberError?.Description,
               });
          } else if (addMemberError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: addMemberError?.Subject,
                    body: addMemberError?.Description,
               });
          } else if (sasTokenError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: sasTokenError?.Subject,
                    body: sasTokenError?.Description,
               });
          } else if (postAddTradePlatesError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: postAddTradePlatesError?.Subject,
                    body: postAddTradePlatesError?.Description,
               });
          } else if (postAddTransporterPlatesError) {
               setAlert({
                    ...alert,
                    show: true,
                    header: postAddTransporterPlatesError?.Subject,
                    body: postAddTransporterPlatesError?.Description,
               });
          }
     }, [
          updateMemberError,
          addMemberError,
          sasTokenError,
          postAddTradePlatesError,
          postAddTransporterPlatesError]);

     const {
          handleSubmit,
          values,
          errors,
          setFieldValue
     } = formik;

     const memberTypeFieldValue = getIn(values, MemberKeys.MemberType);
     useEffect(() => {
          setDisplayIsModelSupplier(memberTypeFieldValue == MemberType.TransportCompany);
     }, [memberTypeFieldValue])

     return (
          <FormikProvider value={formik}>
               <Alert {...alert} />
               <ShowPleaseWait show={showPleaseWait} />
               <Loading loading={isLoading} />
               <FormContainer
                    onSubmit={(e) => {
                         e.preventDefault();
                         if (errors && Object.keys(errors).length) {
                              scrollToFirstError(errors);
                              return;
                         }
                         handleSubmit(e);
                    }}
               >
                    <FormFieldSetContainer>
                         {/* Credit report */}
                         <FieldSetContainer id='creditReportSection'>
                              <FieldSetHeader>Credit report</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.MovexSetLimit}
                                             label={!isCreditEditable ?
                                                  <Tooltip title='Please contact the administrator for authorization to amend this field.'>
                                                       <span>
                                                            <span>Movex Set Limit</span>
                                                            <span style={{ margin: '0 10px' }}></span>
                                                            <InfoIcon className='fa fa-info' />
                                                       </span>
                                                  </Tooltip> : 'Movex Set Limit'
                                             }
                                             min={0}
                                             disabled={isCreditEditable ? false : true}
                                             type='number'
                                             step="any"
                                             text='£'
                                             control={FormControlKeys.InputGroup}
                                        />
                                        <FormikControl name={MemberKeys.DelphiScore} label='Delphi Score' min={0} type='number' />
                                        <FormikControl
                                             name={MemberKeys.PaymentType}
                                             control={FormControlKeys.Select}
                                             label='Suggested Payment Type'
                                             placeholder='Select'
                                             options={convertEnumToSelectListWithIntegerValue(CreditPaymentType)}
                                        />
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.RecommendedLimit}
                                             label='Experian Recommended Limit'
                                             min={0}
                                             type='number'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Risk}
                                             control={FormControlKeys.Select}
                                             label='Risk'
                                             options={convertEnumToSelectListWithIntegerValue(CreditRisk)}
                                             placeholder='Select'
                                        />
                                        <FormikControl name={MemberKeys.DateOfCreditReport} label='Date of report' type='date' />
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.UsedCredit}
                                             label='Used Credit'
                                             disabled='disabled'
                                             min={0}
                                             type='number'
                                             value={usedLimit}
                                             control={FormControlKeys.InputGroup}
                                             text='£'
                                        />
                                        <FormikControl
                                             name={MemberKeys.RemainingCredit}
                                             label='Remaining Credit'
                                             control={FormControlKeys.InputGroup}
                                             text='£'
                                             disabled='disabled'
                                             value={remainingLimit}
                                             min={0}
                                             type='number'
                                        />
                                        <label>
                                             <FormikControl
                                                  style={fileInputStyle}
                                                  name={MemberKeys.CreditReportFile}
                                                  label='Credit Report'
                                                  type='file'
                                                  onChange={(e: any) => {
                                                       onFileChange(e, DocumentType.CreditReport);
                                                       setFieldValue(MemberKeys.CreditReportFile, e.target.value);
                                                  }}
                                                  disabled={isDocumentUploaded(DocumentType.CreditReport)}
                                                  title={documentTitle}
                                             />
                                        </label>
                                        <FileUploadContent
                                             documentType={DocumentType.CreditReport}
                                             inputName={MemberKeys.CreditReportFile}
                                             memberDocuments={memoizedDocuments}
                                             tempImageFiles={imageFiles}
                                             handleRemoveDocument={removeUploadedDocument}
                                             handleRemoveAllDocumentsByType={removeAllUploadedDocumentsByType}
                                        />
                                   </Col>
                              </Row>
                         </FieldSetContainer>
                         {/* Coda Information */}
                         <FieldSetContainer id='codaInfoSection'>
                              <LineBreak />
                              <FieldSetHeader>Coda Information</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl name={MemberKeys.CodaCustomerReference} label='Customer Reference' />
                                   </Col>
                                   <Col>
                                        <FormikControl name={MemberKeys.CodaSupplierReference} label='Supplier Reference' />
                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Login Information */}
                         <FieldSetContainer id='loginInfoSection'>
                              <LineBreak />
                              <FieldSetHeader>Login Information</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl name={MemberKeys.UserName} label='UserName' showRequired />
                                        <FormikControl
                                             control={FormControlKeys.ClickToUpdateField}
                                             name={MemberKeys.Password}
                                             label='Password'
                                             type='password'
                                             showRequired
                                        />
                                   </Col>
                                   <Col>
                                        <FormikControl name={MemberKeys.Email} label='Email' showRequired />
                                        <FormikControl
                                             control={FormControlKeys.ClickToUpdateField}
                                             name={MemberKeys.ConfirmPassword}
                                             label='Confirm Password'
                                             type='password'
                                             showRequired
                                        />
                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Membership Information */}
                         <FieldSetContainer id='membershipInfoSection'>
                              <LineBreak />
                              <FieldSetHeader>Membership Information</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.MemberType}
                                             control={FormControlKeys.Select}
                                             label='Membership Type'
                                             options={AdminMemberTypeOptions}
                                        />
                                        <FormikControl
                                             name={MemberKeys.ParentID}
                                             control={FormControlKeys.Select}
                                             label='Group Membership'
                                             options={groupsForDropdown}
                                        />
                                        <FormikControl
                                             name={MemberKeys.CustomerSetID}
                                             control={FormControlKeys.Select}
                                             label='Customer Set'
                                             options={customerSetForDropdown}
                                             selected={customerSetID}
                                             onChange={(e: any) => setCustomerSetID(e.target.value)}
                                        />
                                        {displayIsModelSupplier && (
                                             <>
                                                  <FormikControl
                                                       name={MemberKeys.IsModelSupplier}
                                                       control={FormControlKeys.Checkbox}
                                                       label="Is MoDel Supplier?"
                                                  />

                                                  {values.IsModelSupplier && (
                                                       <FormikControl
                                                            name={MemberKeys.ModelAccountId}
                                                            label="MoDel Account ID (VMID)"
                                                       />
                                                  )}
                                             </>
                                        )}
                                        <FormikControl
                                             name={MemberKeys.OnlyAcceptMovexPocPods}
                                             control={FormControlKeys.Checkbox}
                                             label='Exclusively Accept Movex POC/PODs?'
                                        />
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.Active}
                                             control={FormControlKeys.RadioGroup}
                                             options={MembershipActiveStatusOptions}
                                             label='Active Status'
                                        />
                                        {/* Account Disable */}
                                        <div className='mb-3 form-group'>
                                             <label className='fw-600'>Account Disable</label>
                                             <div id='accountDisabledDiv' className='form-check' style={{ marginTop: 10 }}>
                                                  <FormikControl
                                                       name={MemberKeys.Disabled}
                                                       control={FormControlKeys.Radio}
                                                       label='Yes, Account Disabled!'
                                                       radioId={`radioAccountDisabled`}
                                                       checked={values?.Disabled}
                                                       onChange={() => {
                                                            setFieldValue(MemberKeys.Disabled, true);
                                                       }}
                                                  />
                                                  <FormikControl
                                                       name={MemberKeys.Disabled_Reason}
                                                       control={FormControlKeys.Select}
                                                       options={MembershipActiveDisableReasonOptions}
                                                       label='If "Yes", Disabled Reason'
                                                  />
                                                  <FormikControl
                                                       name={MemberKeys.Disabled}
                                                       control={FormControlKeys.Radio}
                                                       label='No, Account Enabled!'
                                                       radioId={`radioAccountEnabled`}
                                                       checked={!values?.Disabled}
                                                       onChange={() => {
                                                            setFieldValue(MemberKeys.Disabled, false);
                                                       }}
                                                  />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.LanguageId}
                                             control={FormControlKeys.Select}
                                             options={languagesForDropdown}
                                             label='Default Language'
                                        />
                                        <div className='mb-3 form-group'>
                                             <div>
                                                  <br />
                                                  <label className='fw-600'>Key Accounts</label>
                                             </div>
                                             <FormikControl
                                                  name={MemberKeys.IsKeyAccount}
                                                  control={FormControlKeys.Checkbox}
                                                  label='Is Key Account'
                                             />
                                             <FormikControl
                                                  name={MemberKeys.CanSeeKeyAccounts}
                                                  control={FormControlKeys.Checkbox}
                                                  label='Can See Key Accounts?'
                                             />
                                             <FormikControl
                                                  name={MemberKeys.ComplianceSubmitted}
                                                  control={FormControlKeys.Checkbox}
                                                  label='Compliance Submitted?'
                                             />
                                             {values.ComplianceSubmitted && (
                                                  <>
                                                       <FormikControl name={MemberKeys.SignedBy} label='Signed By' />
                                                       <FormikControl name={MemberKeys.PositionHeld} label='Position Held' />
                                                       <FormikControl label='Submitted On' name={MemberKeys.SupplierComplianceDate} type='date' />
                                                  </>
                                             )}
                                             <FormikControl
                                                  name={MemberKeys.ExpectationsSubmitted}
                                                  control={FormControlKeys.Checkbox}
                                                  label='Expectations Submitted?'
                                             />
                                             {values.ExpectationsSubmitted && (
                                                  <FormikControl label='Submitted On' name={MemberKeys.SupplierExpectationsDate} type='date' />
                                             )}
                                        </div>
                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Main Contact */}
                         <FieldSetContainer id='mainContactSection'>
                              <LineBreak />
                              <FieldSetHeader>Main Contact</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl name={MemberKeys.Contact_Name1} label='Contact' />
                                        <FormikControl label='Date Of Birth' name={MemberKeys.DateOfBirth} type='date' />
                                        <FormikControl name={MemberKeys.Landline} label='Tel' />
                                        <FormikControl name={MemberKeys.Mobile} label='Mob' />
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.MainContactPhotoID}
                                             label='Photo ID'
                                             type='file'
                                             style={fileInputStyle}
                                             onChange={(e: any) => {
                                                  const files = e.target.files;
                                                  const uploadedImageFiles = imageFiles.filter(
                                                       (report) => report.type !== DocumentType.PhotoIdentification
                                                  );
                                                  setImageFiles([
                                                       ...uploadedImageFiles,
                                                       { ...files, type: DocumentType.PhotoIdentification },
                                                  ]);
                                                  onFileChange(e, DocumentType.PhotoIdentification);
                                             }}
                                             disabled={isDocumentUploaded(DocumentType.PhotoIdentification)}
                                             title={documentTitle}
                                        />
                                        <FileUploadContent
                                             documentType={DocumentType.PhotoIdentification}
                                             inputName={MemberKeys.MainContactPhotoID}
                                             showImagePreview={false}
                                             memberDocuments={memoizedDocuments}
                                             tempImageFiles={imageFiles}
                                             handleRemoveDocument={removeUploadedDocument}
                                             handleRemoveAllDocumentsByType={removeAllUploadedDocumentsByType}
                                        />

                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Partner Contact */}
                         <FieldSetContainer id='partnerContactSection'>
                              <LineBreak />
                              <FieldSetHeader>Partner Contact</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.IsThereAPartner}
                                             control={FormControlKeys.Checkbox}
                                             label='Is There A Partner?'
                                        />
                                        <FormikControl name={MemberKeys.Partner_ContactName} label='Contact' />
                                        <FormikControl name={MemberKeys.Partner_Email} label='Email' />
                                   </Col>
                                   <Col>
                                        <FormikControl name={MemberKeys.Partner_Landline} label='Landline' />
                                        <FormikControl name={MemberKeys.Partner_Mobile} label='Mobile' />
                                        <div>
                                             <div>
                                                  <label>Proof of Address</label>
                                             </div>
                                             {/* TODO:test this */}
                                             {proofOfPartnerAddressDocs.length > 0 ? (
                                                  proofOfPartnerAddressDocs?.map((doc) => (
                                                       <a href={`${apiUrl}/v1/Files/GetMembersDocument=${memberID}`}>{doc.name}</a>
                                                  ))
                                             ) : (
                                                  <strong className='text-danger'>No Proof of Partner Address Uploaded!</strong>
                                             )}
                                        </div>
                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Company Information< */}
                         <FieldSetContainer id='companyInfoSection'>
                              <LineBreak />
                              <FieldSetHeader>Company Information</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.Company}
                                             label='Company'
                                             onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                  setFieldValue(MemberKeys.Company, e.currentTarget.value)
                                             }
                                        />
                                        <FormikControl name={MemberKeys.Company_YearEstablished} label='Year Established' />
                                        <FormikControl
                                             name={MemberKeys.Company_TradingStatus}
                                             control={FormControlKeys.Select}
                                             label='Trading Status'
                                             placeholder='Select'
                                             options={TradingStatusOptions}
                                        />
                                        <FormikControl name={MemberKeys.Company_RegistrationNumber} label='Registration Number' />
                                        <FormikControl name={MemberKeys.Website} label='Website' />
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.Address1}
                                             label='Address 1'
                                             onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                  setFieldValue(MemberKeys.Address1, e.currentTarget.value)
                                             }
                                        />
                                        <FormikControl name={MemberKeys.Address2} label='Address 2' />
                                        <FormikControl name={MemberKeys.Address3} label='Address 3' />
                                        <FormikControl
                                             name={MemberKeys.Town}
                                             label='Town'
                                             onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                  setFieldValue(MemberKeys.Town, e.currentTarget.value)
                                             }
                                        />
                                        <FormikControl
                                             name={MemberKeys.County}
                                             label='County'
                                             onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                  setFieldValue(MemberKeys.County, e.currentTarget.value)
                                             }
                                        />
                                        <FormikControl name={MemberKeys.PostCode} label='Postcode' />
                                        <div className='postcode-lookup-div'>
                                             <a href='#lookup' onClick={(e) => onPostCodeLookup(e, values.PostCode, false)}>
                                                  Lookup
                                             </a>
                                             <Loading loading={isPostCodeLookupLoading} />
                                             <LineBreak />
                                             <FieldError>{postCodeLookupError}</FieldError>
                                             {postCodeLookup.length > 0 && (
                                                  <FormikControl
                                                       name={MemberKeys.PostCodeLookup}
                                                       control={FormControlKeys.Select}
                                                       options={postCodeLookup}
                                                       onChange={() => {
                                                            onPostCodeLookupChange(false);
                                                       }}
                                                  />
                                             )}
                                        </div>
                                        <FormikControl
                                             name={MemberKeys.Country}
                                             control={FormControlKeys.Select}
                                             label='Country'
                                             placeholder='Select'
                                             options={AdminCountryOptions}
                                        />
                                        <FormikControl
                                             name={MemberKeys.ProofOfBusinessAddress}
                                             label='Proof of Business Address'
                                             type='file'
                                             style={fileInputStyle}
                                             onChange={(e: any) => {
                                                  onFileChange(e, DocumentType.ProofOfBusinessAddress);
                                             }}
                                             title={documentTitle}
                                        />
                                        <FileUploadContent
                                             documentType={DocumentType.ProofOfBusinessAddress}
                                             inputName={MemberKeys.ProofOfBusinessAddress}
                                             memberDocuments={memoizedDocuments}
                                             tempImageFiles={imageFiles}
                                             handleRemoveDocument={removeUploadedDocument}
                                             handleRemoveAllDocumentsByType={removeAllUploadedDocumentsByType}
                                        />
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.IsRegisteredAddressDifferent}
                                             label='Is Registered Address Different?'
                                             control={FormControlKeys.Checkbox}
                                        />
                                        <FormikControl name={MemberKeys.RegisteredAddress1} label='Registered  Address 1' />
                                        <FormikControl name={MemberKeys.RegisteredAddress2} label='Registered  Address 2' />
                                        <FormikControl name={MemberKeys.RegisteredAddress3} label='Registered  Address 3' />
                                        <FormikControl name={MemberKeys.RegisteredTown} label='Registered  Town' />
                                        <FormikControl name={MemberKeys.RegisteredCounty} label='Registered  County' />
                                        <FormikControl name={MemberKeys.RegisteredPostCode} label='Registered  Postcode' />
                                        <div className='postcode-lookup-div'>
                                             <a
                                                  href='#lookup'
                                                  onClick={(e) => {
                                                       if (values.RegisteredPostCode)
                                                            onPostCodeLookup(e, values?.RegisteredPostCode, true);
                                                  }}
                                             >
                                                  Lookup
                                             </a>
                                             <Loading loading={isRegisteredPostCodeLookupLoading} />
                                             <LineBreak />
                                             <FieldError>{registeredPostCodeLookupError}</FieldError>
                                             {registeredPostCodeLookup.length > 0 && (
                                                  <FormikControl
                                                       name={MemberKeys.PostCodeLookup}
                                                       control={FormControlKeys.Select}
                                                       options={registeredPostCodeLookup}
                                                       onChange={() => {
                                                            onPostCodeLookupChange(true);
                                                       }}
                                                  />
                                             )}
                                        </div>
                                        <FormikControl
                                             name={MemberKeys.RegisteredCountry}
                                             label='Registered Country'
                                             control={FormControlKeys.Select}
                                             placeholder='Select'
                                             options={AdminCountryOptions}
                                        />
                                   </Col>
                              </Row>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.Company_Description}
                                             label='Description'
                                             control={FormControlKeys.Textarea}
                                        />
                                   </Col>
                              </Row>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.VATRegistered}
                                             label='VAT Registered'
                                             options={YesNoOptions}
                                             control={FormControlKeys.RadioGroup}
                                        />
                                   </Col>
                                   <Col>
                                        <FormikControl name={MemberKeys.VATNo} label='VAT No.' />
                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Insurance Information (Transporter) */}
                         <FieldSetContainer id='insuranceInfoSection'>
                              <LineBreak />
                              <FieldSetHeader>Insurance Information (Transporter)</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.DrivenInsuranceCoverValue}
                                             label='Driven Insurance Cover'
                                             min={0}
                                             type='number'
                                             step='any'
                                             control={FormControlKeys.InputGroup}
                                             text='£'
                                        />
                                        <FormikControl
                                             name={MemberKeys.TransportedSingleVehicleInsuranceCoverValue}
                                             label='Transported Single Vehicle Insurance Cover'
                                             min={0}
                                             type='number'
                                             step='any'
                                             control={FormControlKeys.InputGroup}
                                             text='£'
                                        />
                                        <FormikControl
                                             name={MemberKeys.TransportedLoadLimitInsuranceCoverValue}
                                             label='Transported Load Limit Insurance Cover'
                                             min={0}
                                             type='number'
                                             step='any'
                                             control={FormControlKeys.InputGroup}
                                             text='£'
                                        />
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.DrivenInsuranceRenewalDate}
                                             label='Driven Insurance Renewal Date'
                                             type='date'
                                        />
                                        <FormikControl
                                             name={MemberKeys.TransportedSingleVehicleInsuranceRenewalDate}
                                             label='Transported Single Vehicle Insurance Renewal Date'
                                             type='date'
                                        />
                                        <FormikControl
                                             name={MemberKeys.TransportedLoadLimitInsuranceRenewalDate}
                                             label='Transported Load Limit Insurance Renewal Date'
                                             type='date'
                                        />
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.TransporterInsuranceDocuments}
                                             label='Documents'
                                             type='file'
                                             style={fileInputStyle}
                                             onChange={(e: any) => {
                                                  onFileChange(e, DocumentType.InsuranceDocument);
                                             }}
                                             title={documentTitle}
                                        />
                                        <FileUploadContent
                                             documentType={DocumentType.InsuranceDocument}
                                             inputName={MemberKeys.TransporterInsuranceDocuments}
                                             showImagePreview={false}
                                             allowRemoveAll={true}
                                             memberDocuments={memoizedDocuments}
                                             tempImageFiles={imageFiles}
                                             handleRemoveDocument={removeUploadedDocument}
                                             handleRemoveAllDocumentsByType={removeAllUploadedDocumentsByType}
                                        />

                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Trade Plates */}
                         {getMemberResponse &&
                              refetchMember &&
                              getMemberResponse?.Member?.MemberType === MemberType.TransportCompany && (
                                   <FieldSetContainer id='tradePlates'>
                                        <LineBreak />
                                        <FieldSetHeader>Trade Plates</FieldSetHeader>
                                        <Row>
                                             <label className='col-form-label col-sm-1 fw-600'>Trade Plates</label>
                                             <Col sm='auto'>
                                                  <FormikControl
                                                       name={MemberKeys.TradePlates}
                                                       label='Add Trade Plate'
                                                       control={FormControlKeys.Button}
                                                       onClick={() => {
                                                            if (!values?.TradePlates?.length) {
                                                                 formik.setFieldError(
                                                                      MemberKeys.TradePlates,
                                                                      'Please provide at least one trade plate.'
                                                                 );
                                                                 return;
                                                            }

                                                            var tradePlatesArray = parsePlateInput(values.TradePlates);

                                                            for (let i = 0; i < tradePlatesArray.length; i++) {
                                                                 if (!verifyPlateInput(tradePlatesArray[i])) {
                                                                      formik.setFieldError(
                                                                           MemberKeys.TradePlates,
                                                                           'Invalid plates input. Plates should only contain alphanumeric characters.'
                                                                      );
                                                                      return;
                                                                 }
                                                            }

                                                            var payload = {
                                                                 memberId: memberID,
                                                                 tradePlates: tradePlatesArray,
                                                            } as AddTradePlateRequest;
                                                            postAddTradePlates(payload);
                                                            formik.setFieldValue(MemberKeys.TradePlates, '');
                                                            refetchMember();
                                                       }}
                                                  />
                                             </Col>
                                             <Col sm='auto' style={{ marginLeft: '-20px' }}>
                                                  <Tooltip title='You can add multiple plates by using commas.'>
                                                       <span>
                                                            <i className='fa fa-info'></i>
                                                       </span>
                                                  </Tooltip>
                                             </Col>

                                             <Col sm='auto'>
                                                  <FormikControl
                                                       style={{ width: '200px' }}
                                                       name={MemberKeys.TradePlates}
                                                       control={FormControlKeys.LabelledInput}
                                                  />
                                             </Col>
                                        </Row>

                                        <div className='row'>
                                             <label className='col-form-label col-sm-1 fw-600'></label>
                                             <Col>
                                                  <PlatesList
                                                       plates={getMemberResponse?.TradePlates as TradePlate[]}
                                                       refreshMember={refetchMember}
                                                       memberId={memberID}
                                                  />
                                             </Col>
                                        </div>
                                        <div className='mb-3 row form-group'>
                                             <Row>
                                                  <label className='col-form-label col-sm-1 fw-600'>Transporter Plates</label>
                                                  <Col sm='auto'>
                                                       <FormikControl
                                                            name={MemberKeys.TransporterPlates}
                                                            label='Add Transporter Plate'
                                                            control={FormControlKeys.Button}
                                                            onClick={() => {
                                                                 if (!values?.TransporterPlates?.length) {
                                                                      formik.setFieldError(
                                                                           MemberKeys.TransporterPlates,
                                                                           'Please provide at least one transporter plate.'
                                                                      );
                                                                      return;
                                                                 }

                                                                 var transporterPlatesArray = parsePlateInput(
                                                                      values.TransporterPlates
                                                                 );

                                                                 for (let i = 0; i < transporterPlatesArray.length; i++) {
                                                                      if (!verifyPlateInput(transporterPlatesArray[i])) {
                                                                           formik.setFieldError(
                                                                                MemberKeys.TransporterPlates,
                                                                                'Invalid plates input. Plates should only contain alphanumeric characters.'
                                                                           );
                                                                           return;
                                                                      }
                                                                 }

                                                                 var payload = {
                                                                      memberId: memberID,
                                                                      transporterPlates: transporterPlatesArray,
                                                                 } as AddTransporterPlateRequest;
                                                                 postAddTransporterPlates(payload);
                                                                 formik.setFieldValue(MemberKeys.TransporterPlates, '');
                                                                 refetchMember();
                                                            }}
                                                       />
                                                  </Col>

                                                  <Col sm='auto' style={{ marginLeft: '-20px' }}>
                                                       <Tooltip title='You can add multiple transporter plates by using commas.'>
                                                            <span>
                                                                 <i className='fa fa-info'></i>
                                                            </span>
                                                       </Tooltip>
                                                  </Col>

                                                  <Col sm='auto'>
                                                       <FormikControl
                                                            style={{ width: '200px' }}
                                                            name={MemberKeys.TransporterPlates}
                                                            control={FormControlKeys.LabelledInput}
                                                       />
                                                  </Col>
                                             </Row>
                                             <label className='col-form-label col-sm-1 fw-600'></label>
                                             <Col>
                                                  <PlatesList
                                                       plates={getMemberResponse?.TransporterPlates as TransporterPlate[]}
                                                       refreshMember={refetchMember}
                                                       memberId={memberID}
                                                  />
                                             </Col>
                                        </div>
                                   </FieldSetContainer>
                              )}

                         {getMemberResponse &&
                              (getMemberResponse?.Member?.MemberType === MemberType.Driver
                                   || getMemberResponse?.Member?.MemberType === MemberType.GeneralPublic
                                   || getMemberResponse?.Member?.MemberType === MemberType.MotorDealer) && (
                                   <FieldSetContainer id='tradePlates'>
                                        <LineBreak />
                                        <FieldSetHeader>Trade Plates</FieldSetHeader>
                                        No Trade Plates for this membership type.
                                   </FieldSetContainer>
                              )}

                         {/* Service Information */}
                         <FieldSetContainer id='serviceInfoSection'>
                              <LineBreak />
                              <FieldSetHeader>Service Information</FieldSetHeader>
                              <Row>
                                   <div className='mb-3 row form-group'>
                                        <label className='fw-600'>Coverage Area</label>
                                        <Col>
                                             <FormikControl
                                                  name={MemberKeys.CoverageArea_UK}
                                                  control={FormControlKeys.Checkbox}
                                                  label='UK'
                                             />
                                        </Col>
                                        <Col>
                                             <FormikControl
                                                  name={MemberKeys.CoverageArea_Ireland}
                                                  control={FormControlKeys.Checkbox}
                                                  label='Ireland'
                                             />
                                        </Col>
                                        <Col>
                                             <FormikControl
                                                  name={MemberKeys.CoverageArea_Europe}
                                                  control={FormControlKeys.Checkbox}
                                                  label='Europe'
                                             />
                                        </Col>
                                   </div>
                              </Row>
                              <Row>
                                   <div className='mb-3 row form-group'>
                                        <label className='fw-600'>Service Type</label>
                                        <Col>
                                             <FormikControl
                                                  name={MemberKeys.ServiceTypeDriven}
                                                  control={FormControlKeys.Checkbox}
                                                  label='Trade Plate'
                                             />
                                             <FormikControl
                                                  name={MemberKeys.ServiceTypeTransported}
                                                  control={FormControlKeys.Checkbox}
                                                  label='Transported'
                                             />
                                        </Col>
                                   </div>
                              </Row>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.DriversHaveIDs}
                                             control={FormControlKeys.Checkbox}
                                             label='Drivers Have IDs?'
                                        />
                                        <FormikControl
                                             name={MemberKeys.DriversHaveUniforms}
                                             control={FormControlKeys.Checkbox}
                                             label='Drivers Have Uniforms?'
                                        />
                                        <FormikControl name={MemberKeys.Hgv} control={FormControlKeys.Checkbox} label='HGV' />
                                   </Col>
                              </Row>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.NoOfDrivers}
                                             label='No of Trade Plates'
                                             min={0}
                                             type='number'
                                        />
                                        <FormikControl
                                             name={MemberKeys.AvgVehiclesMoved}
                                             control={FormControlKeys.Select}
                                             label='Average Vehicles Moved'
                                             placeholder='Select'
                                             options={AvgVehiclesMovedOptions}
                                        />
                                        <FormikControl
                                             name={MemberKeys.TypeOfBusiness}
                                             control={FormControlKeys.Select}
                                             placeholder='Select'
                                             label='Type Of Business'
                                             options={TypeOfBusinessOptions}
                                        />
                                        <FormikControl name={MemberKeys.TypeOfBusiness_Other} label='Please Specify' />
                                        <FormikControl
                                             name={MemberKeys.HearAboutMovex}
                                             control={FormControlKeys.Select}
                                             label='Hear about Movex'
                                             options={HearAboutMovexOptions}
                                             placeholder='Select'
                                        />
                                        <FormikControl name={MemberKeys.HearAboutMovex_Other} label='Please Specify' />
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.NoOfTransporters}
                                             label='No of Transporters'
                                             min={0}
                                             type='number'
                                        />
                                        <div className='mb-3 form-group'>
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={MemberKeys.EnclosedTransport}
                                                  label='Enclosed Transport'
                                             />
                                        </div>
                                        <div className='mb-3 form-group'>
                                             <label className='fw-600'>Classic/Prestige Service</label>
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={MemberKeys.ClassicService}
                                                  label='Classic'
                                             />
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={MemberKeys.PrestigeService}
                                                  label='Prestige'
                                             />
                                        </div>
                                        <div className='mb-3 form-group'>
                                             <label className='fw-600'>Can Transport</label>
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={MemberKeys.CanTransport_Cars}
                                                  label='Cars'
                                             />
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={MemberKeys.CanTransport_Vans}
                                                  label='Vans'
                                             />
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={MemberKeys.CanTransport_Hgvs}
                                                  label='HGVs'
                                             />
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={MemberKeys.CanTransport_Special}
                                                  label='Special'
                                             />
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={MemberKeys.CanTransport_MotorCycles}
                                                  label='Motorcycles'
                                             />
                                        </div>
                                        <div className='mb-3 row form-group'>
                                             <label className='fw-600'>Transporter Configuration</label>
                                             <FormikControl
                                                  control={FormControlKeys.RadioGroup}
                                                  name={MemberKeys.TransportersOverThreeAndAHalf}
                                                  label='Transporter(s) weight over 3.5 tonne'
                                                  options={YesNoOptions}
                                             />
                                             <div className='col-md-6'>
                                                  <FormikControl
                                                       name={MemberKeys.NoOfLowLoaders}
                                                       label='Low Loader'
                                                       min={0}
                                                       type='number'
                                                  />
                                                  <FormikControl
                                                       name={MemberKeys.NoOf2To3CarTransporters}
                                                       label='2-3 car transporter'
                                                       min={0}
                                                       type='number'
                                                  />
                                                  <FormikControl
                                                       name={MemberKeys.NoOf8To9CarTransporters}
                                                       label='8-9 car transporter'
                                                       min={0}
                                                       type='number'
                                                  />
                                             </div>
                                             <div className='col-md-6'>
                                                  <FormikControl
                                                       name={MemberKeys.NoOf1CarTransporters}
                                                       label='1 car transporter'
                                                       min={0}
                                                       type='number'
                                                  />
                                                  <FormikControl
                                                       name={MemberKeys.NoOf5To7CarTransporters}
                                                       label='5-7 car transporter'
                                                       min={0}
                                                       type='number'
                                                  />
                                                  <FormikControl
                                                       name={MemberKeys.NoOf10PlusCarTransporters}
                                                       label='10+ car transporter'
                                                       min={0}
                                                       type='number'
                                                  />
                                             </div>
                                             <FormikControl
                                                  name={MemberKeys.ServiceLicenseDocuments}
                                                  label='License Documents'
                                                  type='file'
                                                  style={fileInputStyle}
                                                  onChange={(e: any) => {
                                                       onFileChange(e, DocumentType.LicenseDocument);
                                                  }}
                                                  //disabled={!isEditMode || isDocumentUploaded(DocumentType.LicenseDocument)}
                                                  title={documentTitle}
                                             />
                                             <FileUploadContent
                                                  documentType={DocumentType.LicenseDocument}
                                                  inputName={MemberKeys.ServiceLicenseDocuments}
                                                  allowRemoveAll={true}
                                                  memberDocuments={memoizedDocuments}
                                                  tempImageFiles={imageFiles}
                                                  handleRemoveDocument={removeUploadedDocument}
                                                  handleRemoveAllDocumentsByType={removeAllUploadedDocumentsByType}
                                             />
                                        </div>
                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Payment Information */}
                         <FieldSetContainer id='paymentInfoSection'>
                              <LineBreak />
                              <FieldSetHeader>Payment Information</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.InvoiceFrequency}
                                             control={FormControlKeys.Select}
                                             label='Invoice Frequency'
                                             placeholder='Select'
                                             options={InvoiceFrequencyOptions}
                                        />
                                        <FormikControl name={MemberKeys.InvoiceMaxAmount} label='Invoice Max Amount' />
                                        <FormikControl name={MemberKeys.InvoiceMaxLineItems} label='Invoice Max Line Items' />
                                        <FormikControl name={MemberKeys.BankName} label='Bank Name' />
                                   </Col>
                                   <Col>
                                        <FormikControl
                                             control={FormControlKeys.Select}
                                             name={MemberKeys.PaymentMethod}
                                             label='Payment Method'
                                             placeholder='Select'
                                             options={PaymentMethodOptions}
                                        />
                                        <div className='mb-3 form-group'>
                                             <FormikControl
                                                  control={FormControlKeys.Checkbox}
                                                  name={MemberKeys.PaySuccSelected}
                                                  label='Pay Method Successfully Selected'
                                             />
                                        </div>
                                        <FormikControl name={MemberKeys.PaymentReference} label='Payment Reference' />
                                        <FormikControl
                                             name={MemberKeys.PayStartDate}
                                             label='Recurring Payment Start Date'
                                             type='date'
                                        />
                                   </Col>
                              </Row>
                              <Row style={{ display: isEditMode ? 'block' : 'none' }}>
                                   <Col style={{ width: '50%', display: 'inline-block' }}>
                                        <FormikControl
                                             name={MemberKeys.AccountName}
                                             label='Account Holder Name'
                                             type={hasBankDetailPermission ? 'text' : 'password'}
                                             disabled={!hasBankDetailPermission}
                                             value={hasBankDetailPermission ? values.AccountName : '#################'}
                                        />
                                   </Col>
                                   <Col style={{ width: '50%', display: 'inline-block' }}>
                                        <FormikControl
                                             name={MemberKeys.AccountNo}
                                             maxLength={8}
                                             type={hasBankDetailPermission ? 'text' : 'password'}
                                             label='Account No'
                                             disabled={!hasBankDetailPermission}
                                             value={hasBankDetailPermission ? values.AccountNo : '#################'}
                                        />
                                   </Col>
                                   <Col>
                                        <div className='mb-3 form-group'>
                                             <label className='form-label fw-600'>Sort Code</label>
                                             <div>
                                                  <Field
                                                       maxLength={2}
                                                       name='SortCode1'
                                                       className='form-control'
                                                       style={sortCodeInputStyle}
                                                       disabled={!hasBankDetailPermission}
                                                       type={hasBankDetailPermission ? 'text' : 'password'}
                                                       value={hasBankDetailPermission ? sortCode1 : '##'}
                                                       onChange={(e: any) => setSortCode1(e.target.value)}
                                                  />
                                                  {' - '}
                                                  <Field
                                                       maxLength={2}
                                                       name='SortCode2'
                                                       style={sortCodeInputStyle}
                                                       value={hasBankDetailPermission ? sortCode2 : '##'}
                                                       disabled={!hasBankDetailPermission}
                                                       type={hasBankDetailPermission ? 'text' : 'password'}
                                                       className='form-control'
                                                       onChange={(e: any) => setSortCode2(e.target.value)}
                                                  />
                                                  {' - '}
                                                  <Field
                                                       maxLength={2}
                                                       style={sortCodeInputStyle}
                                                       disabled={!hasBankDetailPermission}
                                                       type={hasBankDetailPermission ? 'text' : 'password'}
                                                       name='SortCode3'
                                                       className='form-control'
                                                       value={hasBankDetailPermission ? sortCode3 : '##'}
                                                       onChange={(e: any) => setSortCode3(e.target.value)}
                                                  />
                                             </div>
                                        </div>
                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Membership Fees */}
                         <FieldSetContainer id='membershipFeesSection'>
                              <LineBreak />
                              <FieldSetHeader>Membership Fees</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.MonthlyFeeType}
                                             control={FormControlKeys.RadioGroup}
                                             label='Monthly Fee Type'
                                             options={MonthlyFeeTypeOptions}
                                        />
                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Email Preferences */}
                         <FieldSetContainer id='emailPreferencesSection'>
                              <LineBreak />
                              <FieldSetHeader>Email Preferences</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <h5>Dealer / Subcontractor</h5>
                                        <FormikControl
                                             name={MemberKeys.Email_RequestQuote}
                                             label='Quotes Requested'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Email_NewOfferReceived}
                                             label='New Offer Received'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Email_OfferUpdated}
                                             label='Offer Updated'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Email_SupplierConfirmsJob}
                                             label='Supplier Confirms Job'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Email_SupplierCompletesProofOfCollection}
                                             label='Supplier Completes POC'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Email_SupplierCompletesProofOfDelivery}
                                             label='Supplier Completes POD'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Email_SupplierCompletesJob}
                                             label='Supplier Completes Job'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Email_InvoiceForJobSent}
                                             label='Invoice Emails'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                   </Col>
                                   <Col>
                                        <h5>Transporter</h5>
                                        <FormikControl
                                             name={MemberKeys.Email_PlaceQuote}
                                             label='Place Quote'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Email_AcceptQuote}
                                             label='Quote Accepted'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Email_RejectQuote}
                                             label='Quote Rejected'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                        <FormikControl
                                             name={MemberKeys.Email_InvoiceToSupplier}
                                             label='Invoices'
                                             placeholder='Enter each email address separated by a comma...'
                                        />
                                   </Col>
                              </Row>
                         </FieldSetContainer>

                         {/* Notes For Admin Use */}
                         <FieldSetContainer id='notesSection'>
                              <LineBreak />
                              <FieldSetHeader>Notes For Admin Use</FieldSetHeader>
                              <Row>
                                   <Col>
                                        <FormikControl
                                             name={MemberKeys.Notes}
                                             control={FormControlKeys.Textarea}
                                             label='Points to Note'
                                        />
                                   </Col>
                              </Row>
                         </FieldSetContainer>
                    </FormFieldSetContainer>
                    <Margin20BottomContainer style={{ height: '3em' }}>
                         <FormikControl
                              control={FormControlKeys.Button}
                              name='SubmitBtn'
                              type='submit'
                              className='btn btn-primary f-r'
                              label={memberID ? 'Update Member' : 'Add Member'}
                         ></FormikControl>
                    </Margin20BottomContainer>
               </FormContainer>
          </FormikProvider>
     );
};

type GeneralFormProps = {
     member?: AddMemberModel;
     isGetMemberLoading?: boolean;
     getMemberResponse?: GetMemberResponse | undefined;
     getMemberError?: any;
     refetchMember?: Function;
};

const sortCodeInputStyle = { width: '10%', display: 'inline' };
const fileInputStyle = { color: '#006dcc' };


export default GeneralForm;
